import React from "react";

export const Button = (props) => {
    return (
        <div id="button">
            <div className="wrapper_button">
                <img src="/img/arrow-left.svg" alt="" class="arrow" />
                <a
                    className="button"
                    onClick={props.onClick}
                >
                    {props.text}
                </a>
                <img src="/img/arrow-right.svg" alt="" class="arrow" />
            </div>
        </div>

    );
}