import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Button } from './button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import emailjs from '@emailjs/browser';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'white !important', // Text color
            fontSize: '14px', // Font size for input text
          },
          '& .MuiFormLabel-root': {
            color: 'white !important', // Label color
            fontSize: '14px', // Font size for label
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid', // Border style
            borderColor: '#0CFFF1 !important', // Border gradient
            borderRadius: '4px', // Border radius
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            color: 'white !important', // Border color when focused
          },
          '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '14px', // Font size for error text
          },
        },
      },
    },
  },
});

const PopupComponent = ({ open, setOpen }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const [errors, setErrors] = useState({
    nameError: '',
    emailError: '',
    subjectError: '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    // Perform validation
    let hasErrors = false;
    const updatedErrors = {
      nameError: '',
      emailError: '',
      subjectError: '',
    };

    if (!name) {
      updatedErrors.nameError = 'Name is required';
      hasErrors = true;
    }
    if (!email) {
      updatedErrors.emailError = 'Email is required';
      hasErrors = true;
    } else if (!validateEmail(email)) {
      updatedErrors.emailError = 'Invalid email format';
      hasErrors = true;
    }
    if (!subject) {
      updatedErrors.subjectError = 'Subject is required';
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(updatedErrors);
      return;
    }
    const form = {
      from_name: name,
      email,
      subject,
      message: content || 'NO MESSAGE',
    }

    emailjs.send("service_aisr7pl", "template_2bp6wfc", form, "DtMDglVN4ZOEai86g")
    setName('');
    setEmail('');
    setSubject('');
    setContent('');

    // Close popup
    handleClose();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div id="popup">
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={handleClose} id="popup">
          <div className="wrapper">
            <div className="first_section">
              <div id="previousButton" onClick={handleClose} />
            </div>
            <div className="second_section">
              <TextField
                id="outlined-basic"
                label="Your Name"
                variant="outlined"
                sx={{ width: '60%', minWidth: '280px' }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.nameError}
                helperText={errors.nameError}
                required
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ width: '60%', minWidth: '280px' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.emailError}
                helperText={errors.emailError}
                required
              />
              <TextField
                id="outlined-basic"
                label="Mail Subject"
                variant="outlined"
                sx={{ width: '60%', minWidth: '280px' }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                error={!!errors.subjectError}
                helperText={errors.subjectError}
                required
              />
              <TextField
                id="outlined-basic"
                label="Content"
                variant="outlined"
                sx={{ width: '100%', minWidth: '280px' }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                multiline
                minRows={10}
                maxRows={10}
              />
              <Button text="Send" onClick={handleSend} />
            </div>
          </div>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default PopupComponent;
