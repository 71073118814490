import classNames from "classnames";
import React from "react";
import { motion } from "framer-motion";

export const Industries = (props) => {
    console.log(props.data);
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    React.useEffect(() => {
        setTitle(props.data ? props.data.elements[0].title : "Loading");
        setDescription(props.data ? props.data.elements[0].text : "Loading");
    }, [props.data]);

    const [animate, setAnimate] = React.useState(false);

    const handleClick = () => {
        // setAnimate(true)
        // setInterval(() => { setAnimate(false) }, 500)
    };
    return (
        <div id="industries">
            <h1 className="page_title">
                {props.data ? props.data.title : "Loading"}
            </h1>
            <div className="wrapper">
                <div className="elements">
                    {props.data ? props.data.elements.map((element, index) => (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            whileInView={{ opacity: 1, scale: 1 }}

                            transition={{
                                delay: 0.5 + index * 0.1,
                                duration: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: "linear",
                                    damping: 5,
                                    stiffness: 100,
                                    restDelta: 0.001
                                }
                            }}

                            className="element" key={index} onClick={() => {
                                setTitle(element.title);
                                setDescription(element.text);
                                handleClick();
                            }}>
                            <img src={element.img} alt="" className="image" />
                            <div className="element_title">
                                {element.title}
                            </div>
                        </motion.div>
                    )) : "Loading"}
                </div>
                <motion.div
                    initial={{ opacity: 0, x: 0 }}
                    whileInView={{ opacity: 1, x: 0 }}

                    transition={{ delay: 0.5, duration: 1 }}

                    className={classNames("details", animate && "animate")}>
                    <h2 className="title">
                        {title}
                    </h2>
                    <p className="description main_text">
                        {description}
                    </p>
                </motion.div>
            </div>
        </div>

    );
}