import React from "react";

export const Footer = (props) => {
    return (props.data ?
        <div id="footer">
            <div className="wrapper">
                <div className="left">
                    <img src={props.data.logo} alt="logo" className="logo" />
                    <p className="name">
                        {props.data.name}
                    </p>
                </div>
                <div className="right">
                    {props.data ? props.data.social.map((element, index) => (
                        <div className="element" key={index}>
                            <img src={element.image} alt="" className="icon" />
                            <a className="link" href={element.link}>
                                {element.text}
                            </a>
                        </div>
                    )) : "Loading"}
                </div>
            </div>
            <p className="copyright">{props.data.copyright}</p>
        </div>
        : "Loading"
    )
}