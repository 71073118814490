import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar, Navigation, Pagination, FreeMode } from "swiper";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useSpringCarousel } from 'react-spring-carousel'

export const Project = (props) => {
    const slides = [
        {
            "title": "Blockchain Development",
            "text": "We specialize in developing custom blockchain solutions that help businesses streamline their operations, secure their data, and reduce costs. From building decentralized applications to implementing smart contracts, we have the expertise to help businesses take advantage of this technology."
        },
        {
            "title": "Artificial Intelligence",
            "text": "Our AI solutions leverage machine learning and predictive analytics to help businesses automate tasks, make better decisions, and improve customer experiences. We build custom AI models that integrate with businesses' existing systems and provide real-time insights to drive growth."
        },
        {
            "title": "Virtual Reality",
            "text": "Virtual Reality development is a key focus at our company. We create custom VR solutions for businesses, from interactive product demos to immersive training experiences. Our VR development services include designing and building VR experiences for various devices and industries."
        },
        {
            "title": "Augmented Reality",
            "text": "Augmented Reality is another area of expertise at our company. We specialize in creating custom AR solutions that integrate with businesses' branding and marketing strategies. Our AR solutions enhance marketing campaigns, product demos, and training experiences for businesses across various industries."
        },
        {
            "title": "Decentralized Finance ",
            "text": "Our DeFi solutions use blockchain technology to create financial applications that offer greater transparency, security, and accessibility. We help businesses leverage DeFi to improve their financial operations and create new opportunities for growth."
        },
        {
            "title": "Financial Technology",
            "text": "Our FinTech solutions help businesses leverage technology to improve their financial operations, reduce costs, and enhance customer experiences. From mobile payment solutions to customized investment platforms, we provide businesses with the tools they need to succeed in today's competitive market."
        }
    ]
    const {
        carouselFragment,
        slideToPrevItem,
        slideToNextItem
    } = useSpringCarousel({
        itemsPerSlide: 3,
        withLoop: true,
        items: slides.map((slide, index) => ({
            id: index,
            renderItem: (
                <div className="swiper-slide">
                    <h2 className="title">{slide.title}</h2>
                    <p className="text">{slide.text}</p>
                </div>
            ),
        })),
    });
    // React.useEffect(() => {
    //     setSlides(props.data ? props.data.slider : [])
    // }, [props.data])
    return (
        <div id="project">
            <div className="wrapper">
                <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}

                    transition={{ delay: 0.5, duration: 1 }}
                    className="text_wrapper">
                    <h1>{props.data ? props.data.title : "Loading"}</h1>
                    <p className="main_text">{props.data ? props.data.text : "Loading"}</p>
                </motion.div>
                {/* <div className="slider_buttons">

                    <div id="previousButton" onClick={() => swiperRef.current.swiper.slidePrev()} />
                </div>

                <div className="slider_buttons">
                    <div id="nextButton" onClick={() => swiperRef.current.swiper.slideNext()} />

                </div> */}
                {/* <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}

                    transition={{ delay: 0.5, duration: 1 }}>

                    <Swiper
                        className="swiper_wrapper"
                        spaceBetween={30}
                        modules={[Navigation]}
                        navigation={true}

                    >

                        {props.data ? props.data.slider.map((slide, index) => (
                            <SwiperSlide className="swiper-slide">
                                <h2 className="title">{slide.title}</h2>
                                <p className="text">{slide.text}</p>
                            </SwiperSlide>
                        )) : <p>"Loading"</p>}
                    </Swiper>
                </motion.div> */}
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}

                    transition={{ delay: 0.5, duration: 1 }}>
                    <div className="swiper_wrapper">
                        {carouselFragment}
                        <div className="button_container">
                            <button onClick={slideToPrevItem} id="previousButton"></button>
                            <button onClick={slideToNextItem} id="nextButton"></button>
                        </div>
                    </div>
                </motion.div>

            </div>

        </div >);
}