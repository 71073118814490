import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu">
      <div className="container_menu">
        <div class="logo">
          <img src="/img/logo.svg" alt="" class="logo_menu" />
          <p class="logo_text">BOBRCORP</p>
        </div>

        <div>

          <input class="menu-btn" type="checkbox" id="menu-btn" />
          <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>

          <ul className="menu">
            <li>
              <a href="#header" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#project" className="page-scroll">
                Project
              </a>
            </li>

            <li>
              <a href="#industries" className="page-scroll">
                Industries
              </a>
            </li>
            <li>
              <a href="#leaders" className="page-scroll">
                Leaders
              </a>
            </li>
          </ul>
        </div>
      </div>

    </nav >
  );
};
