import React, { useState } from "react";
import { Button } from "./button";
import { motion } from "framer-motion"
import PopupComponent from "./popup";

export const Header = (props) => {

  const [open, setOpen] = useState(false);
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="wrapper">
            <div
              className="text">
              <div className="intro-text">
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}>
                  <h1>
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h1>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1, duration: 1 }}>
                  <h2>{props.data ? props.data.subtitle : "Loading"}</h2>
                  {props.data ? props.data.paragraphs.map((paragraph, index) => (

                    index == 0 ? (<p className="main main_text" >
                      {paragraph}
                    </p>) : (<p className="main_text">
                      {paragraph}
                    </p>)

                  )) : <p>"Loading"</p>}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.5, duration: 1 }}>
                  <Button onClick={()=>setOpen(true)} text="Let`s talk" />
                </motion.div>
              </div>

            </div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="logo_wrapper">
              <img src="/img/logo.svg" alt="" class="logo_intro" />
            </motion.div>
          </div>
        </div>
      </div>

      <PopupComponent open={open} setOpen={setOpen}/>
    </header >
  );
};
