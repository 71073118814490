import React from "react";

import { motion } from "framer-motion";
export const Leaders = (props) => {
    console.log(props)
    return (
        <div id="leaders">

            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1.5 }}
                className="title_wrapper">
                <h1 className="title">{props.data ? props.data.title : "Loading"}</h1>

            </motion.div>
            <div className="wrapper">
                {props.data ? (props.data.leaders.map((leader, index) => (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        whileInView={{ opacity: 1, scale: 1 }}

                        transition={{
                            delay: 0.5 + index * 0.1,
                            duration: 3,
                            ease: [0, 0.71, 0.2, 1.01],
                            scale: {
                                type: "linear",
                                damping: 5,
                                stiffness: 100,
                                restDelta: 0.001
                            }
                        }}
                        className="leader" key={index}>
                        <div className="image_wrapper">
                            <img src={leader.image} alt={leader.name} className="image" />
                        </div>
                        <motion.div
                            initial={{ opacity: 0, x: 0 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 1.5 }}
                            className="data">
                            <h3 class="name">{leader.name}</h3>
                            <p class="position">{leader.position}</p>
                            <p class="mail">{leader.mail}</p>
                        </motion.div>
                    </motion.div>
                ))) : "Loading"}
            </div>
        </div>
    );
}